import moment from 'moment'
import dayjs from 'dayjs'

export const formatActivitiesTableDate = (apiDate: string): string => {
  return moment(String(apiDate)).format('DD/MM/YYYY')
}

export const formatLastActionDatatable = (activityTemplate): string => {
  let prefix = 'Added'
  let dateAction = formatActivitiesTableDate(activityTemplate.createdAt)
  if (activityTemplate.createdAt !== activityTemplate.updatedAt && activityTemplate.updatedAt !== null) {
    prefix = 'Modified'
    dateAction = formatActivitiesTableDate(activityTemplate.updatedAt)
  }
  return prefix + ' ' + dateAction
}

export const formatSecondsToMinutes = (seconds: number): number => {
  return Math.floor(seconds / 60)
}

export const buildTemplateImageUrl = (templateUuid: string, etag: string) => {
  return process.env.VUE_APP_API_URL + '/api/v1/activity-template/' + templateUuid + '/image?etag=' + etag
}

export const buildTemplateFileUrl = (templateUuid: string, attachmentUuid: string) => {
  return process.env.VUE_APP_API_URL + '/api/v1/activity-template/' + templateUuid + '/file/' + attachmentUuid
}

export const buildTemplateFileUri = (
  attachmentUuid: string,
  company: string,
  imageUri: string | null = null
): string | null => {
  if (attachmentUuid && attachmentUuid !== 'undefined') {
    return process.env.VUE_APP_API_URL + '/api/v1/attachment/' + attachmentUuid + '?company=' + company
  }
  return imageUri
}

export const buildTemplateFileUriForDownload = (attachmentUuid: string, company: string) => {
  if (attachmentUuid !== 'undefined' && attachmentUuid) {
    return process.env.VUE_APP_API_URL + '/api/v1/attachment/' + attachmentUuid + '?company=' + company
  }
}

export const buildApiResourceUrl = (resource: string) => {
  return process.env.VUE_APP_API_URL + '/api/v1/' + resource
}

export const formatHumanDate = (dateString) => {
  const date = dayjs(dateString)
  return date.format('MMM D, HH:mm')
}

export const getEtagForTemplate = (imageMetadata) => {
  let imageMetadataObject = {} as any
  try {
    imageMetadataObject = JSON.parse(imageMetadata)
    return imageMetadataObject.etag
  } catch (e) {
    return 'no-image-placeholder'
  }
}
