import { Section, Template } from '@/core/helpers/template/TemplateInterface'
import { uuid } from 'vue-uuid'
import { TemplateLocator } from '@/store/modules/TemplateModule'
import { buildTemplateFileUriForDownload } from '@/core/helpers/activity-template'

export const buildWidgetLocator = (activityTemplate, widgetUuid) => {
  const widgetLocator = {
    activeSectionIndex: -1,
    activeWidgetIndex: -1
  } as TemplateLocator

  const templateJson = activityTemplate.value.templateJson

  for (let s = 0; s < templateJson.sections.length; s++) {
    widgetLocator.activeSectionIndex = s
    const section = templateJson.sections[s]
    for (let w = 0; w < section.widgets.length; w++) {
      if (section.widgets[w].widgetUuid === widgetUuid) {
        widgetLocator.activeWidgetIndex = w
        return widgetLocator
      }
    }
  }

  return widgetLocator
}

export const generateNewSection = (): Section => {
  return {
    sectionUuid: uuid.v4(),
    title: 'New section',
    color: null,
    backgroundImage: null,
    widgets: [],
    widgetsMobilePosition: [],
    settings: {
      enabled: false,
      locked: false,
      lockedPassword: '',
      lockedPasswordHint: ''
    } as any
  }
}

export const userColorMap: { [key: string]: string } = {
  uc1: '#F4B12E',
  uc2: '#459EF0',
  uc3: '#F5564E',
  uc4: '#41BF8F',
  uc5: '#9776FF',
  uc6: '#FFFFFF',
  uc7: '#FB6FBB'
}

export const generateNewTemplate = (): Template => {
  return {
    templateUuid: uuid.v4(),
    title: 'Untitled',
    description: 'No description',
    sections: [
      {
        sectionUuid: uuid.v4(),
        title: 'Intro',
        color: null,
        backgroundImage: null,
        widgets: [],
        widgetsMobilePosition: [],
        settings: {
          enabled: true,
          locked: false,
          lockedPassword: '',
          lockedPasswordHint: ''
        } as any
      },
      {
        sectionUuid: uuid.v4(),
        title: 'New section',
        color: null,
        backgroundImage: null,
        widgets: [],
        widgetsMobilePosition: [],
        settings: {
          enabled: false,
          locked: false,
          lockedPassword: '',
          lockedPasswordHint: ''
        } as any
      }
    ]
  }
}

export const getTemplateImagesBySection = (section, company): Array<string> => {
  const sectionImages = [] as Array<string>

  // section background image

  const unsplashUrlRegex = /^https?:\/\/images\.unsplash\.com\/.+$/

  const backgroundImage = section.backgroundImage
  if (backgroundImage !== null && backgroundImage.attachmentUuid !== undefined) {
    const imageUrl = buildTemplateFileUriForDownload(backgroundImage.attachmentUuid, company)
    if (imageUrl !== undefined) {
      sectionImages.push(imageUrl)
    }
  } else if (typeof backgroundImage === 'string' && unsplashUrlRegex.test(backgroundImage)) {
    sectionImages.push(backgroundImage)
  }

  for (let w = 0; w < section.widgets.length; w++) {
    const widget = section.widgets[w]

    // image card background image
    if (widget.type === 'image-card') {
      const image = widget.specific.image
      if (backgroundImage !== null && image.attachmentUuid !== undefined) {
        const imageUrl = buildTemplateFileUriForDownload(image.attachmentUuid, company)
        if (imageUrl !== undefined) {
          sectionImages.push(imageUrl)
        }
      } else if (image?.original !== undefined && unsplashUrlRegex.test(image?.original)) {
        sectionImages.push(image?.original)
      }
    }

    // 360 scenes background images
    if (widget.type === 'image-view-360') {
      for (let s = 0; s < widget.specific.scenes.length; s++) {
        const sceneImage = widget.specific.scenes[s].image
        if (sceneImage.attachmentUuid !== undefined) {
          const imageUrl = buildTemplateFileUriForDownload(sceneImage.attachmentUuid, company)
          if (imageUrl !== undefined) {
            sectionImages.push(imageUrl)
          }
        } else if (sceneImage?.original !== undefined && unsplashUrlRegex.test(sceneImage?.original)) {
          sectionImages.push(sceneImage?.original)
        }
      }
    }
  }
  return sectionImages
}
